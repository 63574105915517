<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 50px">姓名：</td>
                            <td>
                                <el-input
                                        v-model="searchItem.receiver"
                                        size="small"
                                        placeholder="请输入姓名"
                                ></el-input>
                            </td>
                            <td style="width: 50px">电话：</td>
                            <td>
                                <el-input
                                        v-model="searchItem.phone"
                                        size="small"
                                        placeholder="请输入电话"
                                ></el-input>
                            </td>
                            <td style="width: 50px">类型：</td>
                            <td>
                                <el-input
                                        v-model="searchItem.phone"
                                        size="small"
                                        placeholder="请输入电话"
                                ></el-input>
                            </td>
                            <td style="width: 200px; text-align: center">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        size="small"
                                        @click="searchBtn"
                                >搜索
                                </el-button>
                                <el-button
                                        type="primary"
                                        icon="el-icon-refresh-right"
                                        style="margin-left: 5%"
                                        size="small"
                                        @click="reset"
                                >重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                v-loading="loading"
                                element-loading-text="拼命加载中"
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(0, 0, 0, 0.1)"
                                :max-height="tableHeight"
                                style="width: 100%; height: 100%"
                                :border="true"
                                :row-style="{ height: '40px' }"
                                :header-cell-style="{
                background: '#EBEEF8',
                height: '50px',
                'text-align': 'center',
              }"
                                :cell-style="{ padding: '0', height: lineHeight }"
                        >
                            <el-table-column
                                    type="index"
                                    width="50"
                                    align="center"
                            ></el-table-column>
                            <el-table-column
                                    prop="createTime"
                                    label="发送时间"
                                    align="center"
                                    show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column
                                    prop="receiver"
                                    label="接收人"
                                    align="center"
                                    show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column
                                    prop="phone"
                                    label="接收电话"
                                    align="center"
                                    show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column
                                    prop="type"
                                    label="短信类型"
                                    align="center"
                                    show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column
                                    prop="smsContent"
                                    label="发送内容"
                                    align="center"
                                    width="300"
                                    show-overflow-tooltip
                            ></el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination
                            :pageSize="option.pageSize"
                            :pageTotal="totalPage"
                            :pageCurrent.sync="option.pageIndex"
                            :pageList="pageList"
                            @handlePageChange="pageChange"
                            @handleSizeChange="pageSizeChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [15, 20, 30, 50],
                tableHeight: "",
                tableData: [],
                option: {
                    pageSize: 15,
                    pageIndex: 1,
                },
                totalPage: 10,
                lineHeight: "",
                searchItem: {
                    receiver: "",
                    phone: "",
                    pageIndex: 1,
                    pageSize: 15,
                },
                loading: false,
            };
        },
        methods: {
            pageChange(option) {
                this.option.pageIndex = option;
                this.searchBtn();
            },
            pageSizeChange(val) {
                this.option.pageIndex = 1;
                this.option.pageSize = val;
                this.searchBtn();
            },
            searchBtn() {
                this.searchItem.pageIndex = this.option.pageIndex;
                this.searchItem.pageSize = this.option.pageSize;
                this.loading = true;
                this.$api.getSmsSendInfo(this.searchItem).then((res) => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                        this.loading = false;
                    }
                });
            },
            reset() {
                this.searchItem = {
                    receiver: "",
                    phone: "",
                    pageIndex: 1,
                    pageSize: 15,
                };
                this.searchBtn();
            },
        },
        created() {
            this.tableHeight = window.innerHeight * 0.76;
            this.searchBtn();
        },
    };
</script>

<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        color: #20a0ff;
        margin-right: 20px;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .addNum {
        margin-top: 10px;
        width: 100%;
    }

    .input {
        display: flex;
        width: 100%;
    }

    .button {
        margin: 10px 0 0 0;
        text-align: center;
    }

    .input-style {
        display: flex;
        width: 100%;
        margin-top: 10px;
    }

    .text-show {
        padding: 0.5% 1%;
    }

    .button-style {
        padding: 0 1%;
    }

    .input-text {
        width: 180px;
    }

    .page-body {
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 50px;
        margin-top: 0.5%;
        background-color: #ffffff;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .td-body {
        padding: 10px 0;
        white-space: nowrap;
        overflow-x: scroll;
        width: 1180px;
    }

    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .dialog-body {
        max-height: 650px;
        overflow-y: auto;
    }
</style>
